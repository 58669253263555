export const getTransitionsToState = stateMachine => {
  const transitionsToState = {};

  for (const currentState in stateMachine) {
    const stateData = stateMachine[currentState];
    const { on } = stateData;

    for (const transition in on) {
      const targetState = on[transition];
      if (!transitionsToState[targetState]) {
        transitionsToState[targetState] = [];
      }
      transitionsToState[targetState].push(transition);
    }
  }

  const result = [];
  for (const targetState in transitionsToState) {
    result.push({ label: targetState, transitions: transitionsToState[targetState] });
  }

  return result;
};

export const getCurrentFilteredState = searchString => {
  if (!searchString) {
    return null;
  }

  const params = new URLSearchParams(searchString);

  return params.get('state');
};

export const getCurrentFilteredListingId = searchString => {
  if (!searchString) {
    return null;
  }

  const params = new URLSearchParams(searchString);

  return params.get('listingId');
};

export const getCurrentFilteredDateOne = searchString => {
  if (!searchString) {
    return null;
  }

  const params = new URLSearchParams(searchString);

  return params.get('date1');
};

export const getCurrentFilteredDateTwo = searchString => {
  if (!searchString) {
    return null;
  }

  const params = new URLSearchParams(searchString);

  return params.get('date2');
};

import React from 'react';
import css from './ShareWithQRCodeButton.module.css';
import Button from '../Button/Button';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import Modal from '../Modal/Modal';
import { downloadDivAsImage } from './utils';
import NamedLink from '../NamedLink/NamedLink';
import { FormattedMessage } from 'react-intl';

const ShareWithQRCodeButton = () => {
  const [open, setOpen] = useState(false);
  const currentUrl = typeof window !== 'undefined' && window.location.href;
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <div className={css.wrapper}>
      <Button className={css.actionButton} type="button" onClick={() => setOpen(true)}>
        <FormattedMessage id="ShareWithQRCodeButton.label" />
      </Button>
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        onManageDisableScrolling={() => {}}
        containerClassName={isMobile && css.modalContent}
      >
        <div
          className={css.qrWrapper}
          id="capture"
          onClick={() => {
            if (!isMobile) {
              downloadDivAsImage('capture');
            }
          }}
        >
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={currentUrl}
            viewBox={`0 0 256 256`}
          />
        </div>
        {!isMobile && (
          <center>
            <p>Click the qr code to download it for sharing</p>
          </center>
        )}
      </Modal>
    </div>
  );
};

export default ShareWithQRCodeButton;

import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { updateProfileRequest, updateProfileSuccess, updateProfileError } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

export const loadData = () => (dispatch) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const updatePubclidDataClasses = (actionPayload) => {
  return (dispatch, _getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.default'],
    };

    return sdk.currentUser
      .updateProfile({
        publicData: {
          classes: actionPayload
        }
      }, queryParams)
      .then((resp) => {
        return resp;
      })
      .then((response) => {
        dispatch(updateProfileSuccess(response));
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

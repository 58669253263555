import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_TRANSACTION_FEE, propTypes } from '../../util/types';
import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const isValidTransactionFee = transactionFeeLineItem => {
  return transactionFeeLineItem.lineTotal instanceof Money && transactionFeeLineItem.lineTotal.amount <= 0;
};

const LineItemProviderTransactionFeeMaybe = props => {
  const { lineItems, isProvider, marketplaceName, intl } = props;
  const providerTransactionFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_TRANSACTION_FEE && !item.reversal
  );

  let transactionFeeItem = null;

  if (isProvider && providerTransactionFeeLineItem) {
    if (!isValidTransactionFee(providerTransactionFeeLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid transaction fee item:', providerTransactionFeeLineItem);
      throw new Error('Transaction fee should be present and the value should be zero or negative');
    }

    const transactionFee = providerTransactionFeeLineItem.lineTotal;
    const formattedTransactionFee = transactionFee ? formatMoney(intl, transactionFee) : null;

    transactionFeeItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.transactionFee" />
        </span>
        <span className={css.itemValue}>{formattedTransactionFee}</span>
      </div>
    );
  }

  return transactionFeeItem;
};

LineItemProviderTransactionFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderTransactionFeeMaybe;
